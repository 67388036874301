import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  constructor(private toastr: ToastrService, private breakpointObserver: BreakpointObserver) {

    this.toastr.toastrConfig.maxOpened = 3;
    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.toastrConfig.toastClass = "toastr-width ngx-toastr";
    this.toastrPosition();
  }

  duration: number = 5000;
  position: string = "toast-top-right";

  ShowSuccess(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title, { timeOut: this.duration, positionClass: this.position });
  }

  ShowError(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title, { timeOut: this.duration, positionClass: this.position })
  }

  ShowInfo(message: string | undefined, title: string | undefined) {
    this.toastr.info(message, title, { timeOut: this.duration, positionClass: this.position })
  }

  ShowWarning(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title, { timeOut: this.duration, positionClass: this.position })
  }

  toastrPosition() {
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 768px)');
    if (isSmallScreen) {
      this.position = 'toast-top-center';
    }
    else {
      this.position = 'toast-top-right';
    }
  }
}

