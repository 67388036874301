<div class="container-notice">
  <div class="notice-title">
    <h4>Conditions d'utilisations et de ventes</h4>
  </div>
  <hr>
  <div class="notice-content-container">
    <div class="">
      <form [formGroup]="formTermsOfService" (ngSubmit)="onSubmit()">
        <div class="notice-explaination-container">
          <p>
            Qui dit nouveau service et nouveaux contenus dit également nouvelles conditions générales de vente. Veuillez
            les consulter et les valider afin d'accéder à la plateforme
            <b class="app-name">Agritel Farmer</b>.
          </p>
          <p>
            Je reconnaîs avoir pris connaissance des
            <a href="https://files.captainfarmer.com/captainfarmer/Terms_And_Conditions/CGV_Agritel_Farmer.pdf"
              target="_blank" class="cvg-link"> conditions générales de vente et de leur préambule
            </a>.
          </p>
          <p>
            Je les accepte comme partie intégrante de mon contrat <mat-checkbox class="example-margin" color="primary"
              name="termsOfService" formControlName="isCGVValidated"></mat-checkbox>
          </p>

        </div>
        <hr>
        <div class="form-action-notice">
          <button type="submit" [disabled]="formTermsOfService.invalid"  [mat-dialog-close]="true" class="buttonOutlinedTextBoldSmall">Accepter</button>
        </div>
      </form>
    </div>
  </div>
</div>