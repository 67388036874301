import { HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { GlobalParams } from '../../../global';
import { LoginInformations } from '../../../model/LoginInformations';
import { LogInResponse } from '../../../model/LogInResponse';
import { User } from '../../../tools/User';
import { InformationNoticeComponent } from '../../account/information_notice/information_notice.component';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { TabMyProductsComponent } from '../../farm/tab_my_products/tab_my_products.component';
import { CompanyService } from '../http/company.service';
import { LoginService } from '../http/login.service';
import { GlobalFunctionsToken } from 'src/tools/Token';


@Injectable({
  providedIn: 'root'
})

export class ConnexionService {
  constructor(private notifyService: NotificationService, private cookieService: CookieService, public dialog: MatDialog, private connectionService: LoginService, private companyService: CompanyService, private router: Router) {
  }

  tabMyProductsComponentNonPopup: TabMyProductsComponent;
  userNotified: boolean;

  OpenDialogInformationNotice(): void {
    const dialogRef = this.dialog.open(InformationNoticeComponent, {
      width: '700px',
      panelClass: 'middle',
      disableClose: true
    });

    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.isPopup = true;
    dialogRef.afterClosed().subscribe(result => {
      this.companyService.AcceptNoticeInformation().subscribe(() => {
        const requestIsFirstConnexion: any = { isFirstConnexion: false };

        this.companyService.SetFirstConnection(requestIsFirstConnexion).subscribe(() => {

          this.OpenDialogProducts(User.GlobalFunctionsUser.GetDefaultProducts());
        },
          (err: { status: number; }) => {
            if (err.status !== 401) {
              this.notifyService.ShowError("Une erreur s'est produite", "");
            }
          });
      });
    });
  }

  OpenDialogProducts(defaultProducts: number = null): void {
    const dialogRef = this.dialog.open(TabMyProductsComponent, {
      width: '600px',
      panelClass: 'middle',
      disableClose: true
    });


    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.isPopup = true;

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public Login(request: LoginInformations) {
    GlobalParams.BYPASS_SPINNER = new HttpContextToken(() => false);
    this.connectionService.loginUser(request).subscribe({
      next: (logInResponse: LogInResponse) => {

        this.userNotified = logInResponse.isCGVAccepted;

        this.cookieService.set("token", logInResponse.token, 1);

        GlobalParams.BYPASS_HTTPINTERCEPTOR = new HttpContextToken(() => false);

        document.body.style.overflowY = "auto";

        GlobalFunctionsToken.SetConnectionToken(logInResponse.token);
        User.GlobalFunctionsUser.SetDefaultProducts(logInResponse.products);
        User.GlobalFunctionsUser.SetEmail(request.Email);
        User.GlobalFunctionsUser.SetFirstName(logInResponse.firstName);
        User.GlobalFunctionsUser.SetLastName(logInResponse.lastName);
      },
      error: (err: { error: { detail: string; }; }) => {

        if (err.error.detail == "InformationUser") {
          this.notifyService.ShowError("Email ou mot de passe incorrect", "");
        }
        else {
          this.notifyService.ShowError("Erreur de connexion", "");
        }
      },
      complete: () => {
        this.showPolicies(this.userNotified);
        const redirectingUrl = localStorage.getItem("redirectedUrl");
        if (redirectingUrl != null) {
          this.router.navigateByUrl(redirectingUrl);
        } else {
          this.router.navigateByUrl('cotations');
        }

      }
    })
  }
  public showPolicies(hasAcceptPolicies: boolean) {
    if (hasAcceptPolicies === false) {
      this.OpenDialogInformationNotice();
    }
  }

}
