import { Observable } from "rxjs";
import { Service } from "./service";
import { Injectable } from "@angular/core";



@Injectable({
  providedIn: 'root'
})

export class QuestionService extends Service {
  override controller = 'Questions';

  createQuestion(question: string): any {
    let data: Observable<string> = this.HttpPostGeneric(this.controller, "", JSON.stringify(question));
    JSON.parse(JSON.stringify(data))

    return data;
  }

}