import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { QuestionService } from '../services/http/question.service';
import { NotificationService } from '../shared/components/notifications/notification.service';

@Component({
  selector: 'app-form-minute-chrono',
  templateUrl: './form-minute-chrono.component.html',
  styleUrl: './form-minute-chrono.component.scss'
})
export class FormMinuteChronoComponent {

  constructor(private questionService: QuestionService, private notificationService: NotificationService) { }

  askForm = new FormGroup({
    question: new FormControl('', [Validators.required, Validators.maxLength(255)]),
  })

  @ViewChild("formQuestion") formQuestion : NgForm; 


  showConfirmationMessage: boolean = false;
  onClick() {
    this.questionService.createQuestion(this.askForm.value.question!).subscribe({
      error: (err: { status: number; error: string | undefined; }) => {
        if (err.status != 401) {
          this.notificationService.ShowError(err.error, "")
        }

      },

      complete: () => {
        console.info("Send completed");
        this.notificationService.ShowSuccess("Votre question a bien été envoyée", "")
        this.setTimeOutForConfirmationMessageAfterFormSubmit();
        this.askForm.reset();
        this.formQuestion.resetForm(); 
      }
    })
  }


  setTimeOutForConfirmationMessageAfterFormSubmit() {
    this.showConfirmationMessage = true;
    setTimeout(() => {
      this.showConfirmationMessage = false
    }, 5000)
  }

}
