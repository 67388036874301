import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Product } from 'src/model/Product';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { CompanyService } from '../../services/http/company.service';
import { Crop } from 'src/model/analysis/Crop';

@Component({
  selector: 'tabMyProducts',
  templateUrl: './tab_my_products.component.html',
  styleUrls: ['./tab_my_products.component.scss']
})

@Injectable()
export class TabMyProductsComponent implements OnInit {

  constructor(private notifyService: NotificationService, private companyService: CompanyService, public dialogRef: MatDialogRef<TabMyProductsComponent>) {

  }

  cropForm = new FormGroup({
    bleTendre: new FormControl(),
    mais: new FormControl(),
    colza: new FormControl(),
    ourgeFourragere: new FormControl(),
    orgeBrassPrin: new FormControl(),
    orgeBrassHiv: new FormControl(false),
    tournesol: new FormControl(),
    bleDur: new FormControl(),
    engrais: new FormControl(),
  })

  isPopup: boolean = true;
  isPopupClosing: boolean = false;
  title: string = '';
  allCrop: Crop = new Crop;
  products: Array<Product> = new Array<Product>();

  ngOnInit(): void {

    this.getAllCrop();
    this.title = (this.isPopup) ? "Je sélectionne mes cultures" : "Mes cultures";


  }

  getAllCrop() {
    this.companyService.GetCurrentProducts().subscribe({
      next: (data: Crop) => {
        this.allCrop = data;
      },
      err: (err: { status: number; }) => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      },
      complete: () => {
        this.setDefaultValueForm();
      }
    }

    );
  }

  setDefaultValueForm() {
    this.cropForm.setValue({
      bleTendre: this.allCrop.bleTendre,
      mais: this.allCrop.mais,
      colza: this.allCrop.colza,
      ourgeFourragere: this.allCrop.ourgeFourragere,
      orgeBrassPrin: this.allCrop.orgeBrassPrin,
      orgeBrassHiv: false,
      tournesol: this.allCrop.tournesol,
      bleDur: this.allCrop.bleDur,
      engrais: this.allCrop.engrais,
    })

  }

  saveForm() {
    this.companyService.SetCurrentProducts(this.cropForm.value).subscribe({
      err: (err: { status: number; }) => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      },

      complete: () => {
        this.closeDialog();
      }
    }
    )
  }

  closeDialog(): void {
    this.dialogRef.close();
    this.isPopupClosing = true;
  }

}
